import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
//Turbolinks.start();
ActiveStorage.start();

const images = require.context("images", true);
const imagePath = (name) => images(name, true);

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "../css/application.css";

// Companies - Infinite scrolling
document.addEventListener("DOMContentLoaded", function () {
  const postsDiv = document.getElementById("companies-list");
  if (!postsDiv) return;

  const totalPages = parseInt(postsDiv.dataset.totalPages, 10);
  let nextPage = parseInt(postsDiv.dataset.nextPage, 10);
  let isFetching = false;
  let hasReachedEnd = false;

  function shouldLoadMore() {
    const customOffset = 50;
    return (
      window.innerHeight + window.scrollY + customOffset >=
      document.body.scrollHeight
    );
  }

  function updateStateAfterFetch() {
    isFetching = false;
    nextPage++;

    if (nextPage > totalPages) {
      hasReachedEnd = true;
      window.removeEventListener("scroll", onScroll);
    } else {
      postsDiv.dataset.nextPage = nextPage;
    }
  }

  function fetchCompanies() {
    if (hasReachedEnd || isFetching || !nextPage) return;

    isFetching = true;

    Rails.ajax({
      url: postsDiv.dataset.source + ".js",
      type: "get",
      dataType: "script",
      data: `${location.search.slice(1)}&page=${nextPage}`,
      success: function (data) {
        updateStateAfterFetch();
        ReactRailsUJS.mountComponents();
      },
    });
  }

  function onScroll() {
    if (shouldLoadMore()) {
      fetchCompanies();
    }
  }

  window.addEventListener("scroll", onScroll);
});
